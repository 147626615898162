import React from 'react';
import './PolicyPage.scss';

const LegacyPolicyPage = () => {
  return (
    <div className="policy-container">
      <div className="policy-header">
        <h1>Privacy Policy</h1>
        <p className="policy-subtitle">Last Updated: March 21, 2025</p>
      </div>

      <div className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collect, use, and disclose your information when you use our mobile application and related services ("App"). By using the App, you agree to the collection and use of information in accordance with this policy.
        </p>
      </div>

      <div className="policy-section">
        <h2>Information We Collect</h2>
        <h3>Information You Provide</h3>
        <p>
          We may collect information that you provide directly when you:
        </p>
        <ul>
          <li>Register for an account</li>
          <li>Create or share content</li>
          <li>Communicate with other users</li>
          <li>Contact our support team</li>
        </ul>

        <h3>Automatically Collected Information</h3>
        <p>
          When you use our App, we may automatically collect:
        </p>
        <ul>
          <li>Device information (model, operating system, unique device identifiers)</li>
          <li>Log information (IP address, browser type, pages visited)</li>
          <li>Location information (with your permission)</li>
          <li>Usage data (features used, time spent)</li>
        </ul>
      </div>

      <div className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>
          We use the collected information for various purposes, including:
        </p>
        <ul>
          <li>Providing, maintaining, and improving our App</li>
          <li>Processing transactions and fulfilling requests</li>
          <li>Sending service notifications and updates</li>
          <li>Personalizing your experience</li>
          <li>Analyzing usage patterns to enhance functionality</li>
          <li>Detecting and preventing fraudulent or unauthorized activities</li>
        </ul>
      </div>

      <div className="policy-section">
        <h2>Information Sharing and Disclosure</h2>
        <p>
          We may share your information with:
        </p>
        <ul>
          <li>Service providers who perform services on our behalf</li>
          <li>Business partners with your consent</li>
          <li>Legal authorities when required by law</li>
          <li>Other parties in connection with a business transaction, such as a merger</li>
        </ul>
        <p>
          We do not sell your personal information to third parties.
        </p>
      </div>

      <div className="policy-section">
        <h2>Data Security</h2>
        <p>
          We implement appropriate security measures to protect your information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
      </div>

      <div className="policy-section">
        <h2>Your Choices</h2>
        <p>
          You can:
        </p>
        <ul>
          <li>Access, update, or delete your account information</li>
          <li>Opt-out of marketing communications</li>
          <li>Control app permissions such as location access</li>
          <li>Request a copy of your data</li>
        </ul>
      </div>

      <div className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected information from a child under 13, please contact us to have it removed.
        </p>
      </div>

      <div className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </div>

      <div className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <div className="contact-info">
          <p>Email: <a href="mailto:achraf.lafkiri.2@gmail.com">achraf.lafkiri.2@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default LegacyPolicyPage;