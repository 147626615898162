import ofppt from "../assets/images/certifactes/cert.png"
import MongodbUdemy from "../assets/images/certifactes/mongo.jpg"
import MernUdemy from "../assets/images/certifactes/mern.jpg"
import REACTNATIVE from "../assets/images/certifactes/reactnative.jpg"
import NEXT from "../assets/images/certifactes/next.jpg"

const certifactes = [
    {
        id: 1,
        certImage: ofppt,
        link: null
    },
    {
        id: 2,
        certImage: MongodbUdemy,
        link: "https://www.udemy.com/certificate/UC-3f0f3b6e-ef98-43f2-9daf-6fb99135cea5/"
    },
    {
        id: 3,
        certImage: MernUdemy,
        link: "https://www.udemy.com/certificate/UC-10f73c8a-a987-444e-bd1a-cde7df31d1d4/"
    },
    {
        id: 4,
        certImage: REACTNATIVE,
        link: "https://www.udemy.com/certificate/UC-8150f02e-8f50-465c-914a-986db1c52075/"
    },
    {
        id: 5,
        certImage: NEXT,
        link: "https://www.udemy.com/certificate/UC-ba6cc26b-0ec2-4d6b-a906-74dfae67563a/"
    },
]

export default certifactes;