import BorosV2 from "../assets/images/portfolio/borosv2.png";
import BorosV1 from "../assets/images/portfolio/borosv1.png";
import Martex from "../assets/images/portfolio/martex.png";
import GunsShop from "../assets/images/portfolio/guns-shop.png";
import GameMaster from "../assets/images/portfolio/GameMaster.png";
import GoldenCare from "../assets/images/portfolio/goldencare.png";
import PodcastSphere from "../assets/images/portfolio/PodcastSphere.png";
import CareerPro from "../assets/images/portfolio/careerpro.png";
import velocitycycles from "../assets/images/portfolio/velocitycycles.png";
import voip4africa from "../assets/images/portfolio/voip4africa.png";
import tapisserie from "../assets/images/portfolio/tapisserie.png";
import webwiz from "../assets/images/portfolio/webwiz.jpg";
import aceStudio from "../assets/images/portfolio/ace-studios.jpg";
import livraiLiya from "../assets/images/portfolio/livrai_liya.jpg";
import bigwall from "../assets/images/portfolio/bigwall.png";
import lawyer from "../assets/images/portfolio/lawyer.png";
import itsystemes from "../assets/images/portfolio/itsystemes.png";
import resact from "../assets/images/portfolio/resact.png";
import genie from "../assets/images/portfolio/genie.png";
import Solar from "../assets/images/portfolio/solar.webp";
import lafkiriCV from "../assets/images/portfolio/lafkiriCV.webp";
import luxuryHotel from "../assets/images/portfolio/luxuryHotel.webp";
import devfolio from "../assets/images/portfolio/devfolio.png";

// Type : App, template, design

const worksData = [
  {
    title: "Devfolio",
    poster: devfolio,
    previewLink: "https://devfolio.lafkiri.com/",
    type: "template",
  },

  {
    title: "Geniegig",
    poster: genie,
    previewLink: "https://www.genie-gig.lafkiri.com/",
    type: "app",
  },
 
  {
    title: "Resact",
    desc: "Sports Field Reservation Management System",
    poster: resact,
    previewLink: "https://www.resact.ma/",
    type: "app",
  },

  {
    title: "Boros React V2",
    poster: BorosV2,
    previewLink: "https://www.boros.v2.lafkiri.com/",
    type: "website",
  },

  {
    title: "Boros HTML V1",
    poster: BorosV1,
    previewLink: "https://www.boros.lafkiri.com/",
    type: "website",
  },
 
  // {
  //   title: "lawyer App",
  //   poster: lawyer,
  //   previewLink: "https://www.linkedin.com/posts/achraf-lafkiri_aesaefaeqaeyaeqabraenaexaepaezaerabraepaesaeqaeeaepaeyaep-activity-7234271633425772544-g1K6?utm_source=share&utm_medium=member_desktop",
  //   type: "app",
  // },

  // {
  //   title: "IT Systemes",
  //   poster: itsystemes,
  //   previewLink: "",
  //   type: "website",
  // },

  {
    title: "BIGWALL TECHNOLOGY",
    poster: bigwall,
    previewLink: "https://bigwalltechnology.com/",
    type: "website",
  },

  {
    title: "WebWiz",
    poster: webwiz,
    previewLink: "https://webwiz-demo.lafkiri.com/",
    type: "app",
  },

  {
    title: "Lafkiri FREE Resume",
    poster: lafkiriCV,
    previewLink: "https://www.templatemonster.com/resume-templates/achraf-lafkiri-free-printable-resume-templates-417257.html",
    type: "design",
  },
 
  {
    title: "Tapisserie Marrakech",
    poster: tapisserie,
    previewLink: "https://sofa.lafkiri.com/",
    type: "website",
  },

  {
    title: "Livrai Liya",
    poster: livraiLiya,
    previewLink: "https://livrai-liya.com/",
    type: "website",
  },

  {
    title: "CareerPro",
    poster: CareerPro,
    previewLink:
      "https://github.com/achraflafkiri/CareerPro-Advanced-Career-Management-System",
    type: "app",
  },

  {
    title: "Martex",
    poster: Martex,
    previewLink: "https://martex.lafkiri.com",
    type: "design",
  },

  {
    title: "Game Master",
    poster: GameMaster,
    previewLink:
      "https://www.figma.com/file/UUEXfmpJPtZZQhmeAt4ryH/GameMaster's-Interface?type=design&node-id=16%3A2281&mode=design&t=u1T7CN6bTSq9QHPi-1",
    type: "design",
  },

  {
    title: "Velocitycycles",
    poster: velocitycycles,
    previewLink: "https://github.com/achraflafkiri/VelocityCycles",
    type: "app",
  },

  {
    title: "Ace Studio",
    poster: aceStudio,
    previewLink: "https://www.ace-studios.lafkiri.com/",
    type: "website",
  },

  {
    title: "Tapisserie De Rêve",
    poster: tapisserie,
    previewLink: "https://sofa.lafkiri.com/",
    type: "website",
  },

  {
    title: "PhoneValut",
    poster: voip4africa,
    previewLink: "https://phonevalut.lafkiri.com/",
    type: "website",
  },

  {
    title: "PodcastSphere",
    poster: PodcastSphere,
    previewLink: "https://www.podcast-sphere.lafkiri.com/",
    type: "website",
  },

  {
    title: "Golden Care",
    poster: GoldenCare,
    previewLink: "https://www.goldencare.lafkiri.com/",
    type: "website",
  },

  {
    title: "Guns Shop",
    poster: GunsShop,
    previewLink:
      "https://www.figma.com/file/6nv4BUBvW4EijKLOpKzLAF/Guns-shop?type=design&node-id=226%3A12&mode=design&t=XEYfyRtY0Iy2nUQL-1",
    type: "design",
  },
  
  {
    title: "Solar",
    poster: Solar,
    previewLink: "https://demo.templatemonster.com/demo/443244.html?_gl=1*1d7n3sq*_gcl_aw*R0NMLjE3MzczMTU3OTQuQ2owS0NRaUE0cks4QmhEN0FSSXNBRmU1TFhJcmRVV0c3NWI5MlJ3OGp2UmdzaHNRcmlpU2hyZUhfeGJJTS0ybUVxajFydEcxNVdGSF8wVWFBb05iRUFMd193Y0I.*_gcl_au*MTg2Nzc1MDk0OS4xNzM3MTU2MTcx*_ga*MTg4NjAzOTAwMC4xNzM3MTU2MTY3*_ga_FTPYEGT5LY*MTczODEzOTUwOS4zMi4xLjE3MzgxMzk1OTkuNjAuMC4w",
    type: "website",
  },
 
  {
    title: "Luxury Hotel",
    poster: luxuryHotel,
    previewLink: "https://demo.templatemonster.com/demo/441021.html",
    type: "website",
  },

];

export default worksData;
